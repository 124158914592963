export const ACCESS_TOKEN_KEY = '___brs___'

export type Session = {
  accessToken: string
  expiresAt: string
  tokenType: string
}

/**
 * Client-side only methods to persist session
 */
export const getPersistedSession = (): Session | null => {
  if (typeof document === undefined) return null

  const cookies = document.cookie.split('; ')
  const sessionCookie = cookies.find((c) => c.startsWith(ACCESS_TOKEN_KEY))

  if (sessionCookie) {
    return JSON.parse(sessionCookie.split('=')[1]) as Session
  } else {
    return null
  }
}

export const startPersistedSession = (payload: Session): void => {
  if (typeof document === undefined) return

  document.cookie = `${ACCESS_TOKEN_KEY}=${JSON.stringify(
    payload
  )}; SameSite=Strict; path=/; expires=${new Date(
    payload.expiresAt
  ).toUTCString()}`
}

export const endPersistedSession = (): void => {
  if (typeof document === undefined) return

  // date in the past
  document.cookie = `${ACCESS_TOKEN_KEY}=; SameSite=Strict; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`
}
